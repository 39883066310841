/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fontsource/raleway';
import '@fontsource/roboto';
//require('prismjs/themes/prism-tomorrow.css');
require('common/src/assets/css/prism-tomorrow-custom.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');
//require('prismjs/plugins/command-line/prism-command-line.css');
require('common/src/assets/css/prism-command-line-custom.css');
